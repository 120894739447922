import { setPage, addRecords } from './data';

const initialState = {
  dealers: {
    pages: {},
    records: {},
  },
  me: {
    users: undefined,
    dealer: undefined,
  },
  actions: {
    success: false,
    error: null,
  }
};

export default function dealersReducer(state, action) {
  switch (action.type) {
  case 'DEALERS_LOADING':
    return setPage(state, 'dealers', action.query.page, null);
  case 'DEALERS_LOADED':
    {
      const ids = action.dealers.map(dealer => dealer.id);
      const records = action.dealers.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'dealers', action.query.page, ids, action.pageInfo);
      return addRecords(stateWithPage, 'dealers', records);
    }
  case 'DEALER_LOADED':
    {
      const { dealer } = action;
      return addRecords(state, 'dealers', {[dealer.id]: dealer});
    }
  case 'MY_DEALER_LOADED':
    {
      const { dealer } = action;
      return {...state, me: {...state.me, dealer}};
    }
  case 'MY_DEALERS_USERS_LOADED':
    {
      const { users } = action;
      return {...state, me: {...state.me, users}};
    }
  case 'MY_DEALERS_USERS_ACTION_BEGIN':
    return { ...state, actions: {success: false, error: null}};
  case 'MY_DEALERS_USERS_ACTION_SUCCESS':
    return { ...state, actions: {success: true, error: null}};
  case 'MY_DEALERS_USERS_ACTION_ERROR':
    return { ...state, actions: {success: false, error: action.error}};
  default:
    return state || initialState;
  }
}
