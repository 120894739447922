import { capitalizeWords, createKeysObject } from 'utils';

export const action = {
  ACTIVATIONS_SHOW_LOADER: 'ACTIVATIONS_SHOW_LOADER',
  ACTIVATIONS_HIDE_LOADER: 'ACTIVATIONS_HIDE_LOADER',
  ACTIVATIONS_FETCHED: 'ACTIVATIONS_FETCHED',
  ACTIVATIONS_SET_PAGE: 'ACTIVATIONS_SET_PAGE',
  ACTIVATIONS_SET_TOTAL: 'ACTIVATIONS_SET_TOTAL',
};

export const declinedReasons = [
  'didNotRespond',
  'unableToPay',
  'foundBetterDeal',
  'other',
];

export const declinedReason = createKeysObject(declinedReasons);
export const declinedReasonLabel = {
  [declinedReason.didNotRespond]: 'Did not Respond',
  [declinedReason.unableToPay]: 'Unable to Pay',
  [declinedReason.foundBetterDeal]: 'Found better deal',
  [declinedReason.other]: 'Other (leave note)',
};

export const declinedInfoDescription = {
  [declinedReason.didNotRespond]: 'Customer did not respond',
  [declinedReason.unableToPay]: 'Customer is unable to pay',
  [declinedReason.foundBetterDeal]: 'Customer found better deal',
};

export const holdReasons = [
  'wontAcceptOrderYet',
  'needsRQ',
  'existingService',
  'needsAutopay',
  'needsCoSigner',
  'other',
];

export const holdReason = createKeysObject(holdReasons);
export const holdReasonLabel = {
  [holdReason.wontAcceptOrderYet]: 'Provider won\'t accept order yet',
  [holdReason.needsRQ]: 'Needs RQ',
  [holdReason.existingService]: 'Existing Service',
  [holdReason.needsAutopay]: 'Needs Autopay',
  [holdReason.needsCoSigner]: 'Needs Co-signer',
  [holdReason.other]: 'Other (leave note)',
};

export const holdInfoDescription = {
  [holdReason.wontAcceptOrderYet]: 'Provider won\'t accept order yet',
  [holdReason.needsRQ]: 'Needs RQ',
  [holdReason.existingService]: 'Existing Service',
  [holdReason.needsAutopay]: 'Needs Autopay',
  [holdReason.needsCoSigner]: 'Needs Co-signer',
};

export const actionRequiredType = {
  invalidSignupInformation: 'InvalidSignupInformation',
  requiresPayment: 'RequiresPayment',
  needsApproval: 'NeedsApproval',
};

export const paymentTypes = [
  'deposit',
  'installationPayment',
  'upfrontPayment',
];

export const paymentType = createKeysObject(paymentTypes);
export const paymentTypeLabel = {
  [paymentType.deposit]: 'Deposit',
  [paymentType.installationPayment]: 'Installation Payment',
  [paymentType.upfrontPayment]: 'Upfront Payment',
};

export const ACTIVATION_PROCESS_STATE_OPTIONS = [
  'New',
  'In Progress',
  'Rejected',
  'Complete',
].map(k => [k, k]);


export const statuses = [
  'submitted',
  'pending',
  'incomplete',
  'activated',
  'declined',
  'expiring',
  'deactivated',
  'error',
  'cancelled',
  'prepared',
  'phone-ordered',
  'on-hold',
];

export const status = createKeysObject(statuses);
export const statusTitle = Object.values(status).reduce((acc, statusKey) => {
  switch (statusKey) {
    case status.error:
      acc[statusKey] = 'Action Required';
      break;

    case status['phone-ordered']:
      acc[statusKey] = 'Phone Order';
      break;

    case status['on-hold']:
      acc[statusKey] = 'On Hold';
      break;

    case status.pending:
      acc[statusKey] = 'In Progress';
      break;

    default:
      acc[statusKey] = capitalizeWords(statusKey);
  }

  return acc;
}, {});

export const statusOptions = Object.entries(statusTitle);

export const statusTransition = {
  [status.submitted]: [{
    status: status.pending,
    label: 'Start Order',
    color: 'primary',
  }, {
    status: status.declined,
    label: 'Decline Order',
    color: 'danger',
    outlined: true,
  },
  {
    status: status['on-hold'],
    label: 'On Hold',
  },
  {
    status: status.activated,
    label: 'Complete Order',
  }, {
    status: status.error,
    label: 'Action Required',
  }],

  [status['phone-ordered']]: [{
    status: status.pending,
    label: 'Start Order',
    color: 'primary',
  }, {
    status: status.declined,
    label: 'Decline Order',
    color: 'danger',
    outlined: true,
  },
  {
    status: status.cancelled,
    label: 'Cancel Order',
  },
  {
    status: status['on-hold'],
    label: 'On Hold',
    outlined: true,
  }, {
    status: status.activated,
    label: 'Complete Order',
  }, {
    status: status.error,
    label: 'Action Required',
  }],
  [status.pending]: [{
    status: status.activated,
    label: 'Complete Order',
    color: 'success',
  }, {
    status: status.error,
    label: 'Action Required',
    color: 'danger',
    outlined: true,
  }, {
    status: status['on-hold'],
    label: 'On Hold',
  }, {
    status: status.declined,
    label: 'Decline Order',
  }],
  [status.activated]: [{
    status: status.deactivated,
    label: 'Deactivate Order',
    color: 'primary',
  }, {
    status: status.expiring,
    label: 'Order Expiring',
    outlined: true,
  }, {
    status: status.pending,
    label: 'Start Order',
  },
  {
    status: status['on-hold'],
    label: 'On Hold',
  },
  {
    status: status.error,
    label: 'Action Required',
  },
  {
    status: status.declined,
    label: 'Decline Order',
  },
  ],
  [status.declined]: [{
    status: status.pending,
    label: 'Resume Order',
    color: 'primary',
  },
  {
    status: status['on-hold'],
    label: 'On Hold',
    color: 'danger',
    outlined: true,
  },
  ],
  [status['on-hold']]: [{
    status: status.pending,
    label: 'Start Order',
    color: 'primary',
  }, {
    status: status.declined,
    label: 'Decline Order',
    color: 'danger',
    outlined: true,
  },
  {
    status: status.activated,
    label: 'Complete Order',
  }, {
    status: status.error,
    label: 'Action Required',
  }],
  [status.error]: [{
    status: status.pending,
    label: 'Resume order',
    color: 'primary',
  },
  {
    status: status.declined,
    label: 'Decline Order',
    color: 'danger',
    outlined: true,
  },
  {
    status: status['on-hold'],
    label: 'On Hold',
  }],

};

export const ACTIVATION_SOURCE_TYPE = {
  DEALER: 'dealer',
  DIRECT: 'direct',
};

export const ACTIVATION_SOURCE_TYPE_OPTIONS = [
  [ACTIVATION_SOURCE_TYPE.DEALER, 'Call-in Order'],
  [ACTIVATION_SOURCE_TYPE.DIRECT, 'Mobile Order'],
];

export const ActivationEventErrorType = {
  SignedUpdate: 'signed_update',
  StateTransition: 'state_transition',
};