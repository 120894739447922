import { useRef } from 'react';

const TIMER_DELAY = 3000;
const MIN_DELAY = 1000;

const getDefaultTimerValue = () => ({ started: null, paused: null, id: null });

/**
 * I didn't use useMemo or useCallback to memoize functions, maybe it won't be necessary.
 * But if there are components that require constant references, we can improve this behavior.
 * @param {() => void} callback
 * @param {{ delay: number; minDelay: number }} param1
 */
export function useTimer(callback, { delay = TIMER_DELAY, minDelay = MIN_DELAY } = {}) {
  const timer = useRef(getDefaultTimerValue());

  const clearTimer = () => {
    clearTimeout(timer.current.id);

    timer.current = getDefaultTimerValue();
  };

  /** Returns the clear timer function */
  const setTimer = (time = delay) => {
    timer.current.started = new Date().valueOf();
    timer.current.id = setTimeout(callback, time);

    return clearTimer;
  };

  const pauseTimer = () => {
    clearTimeout(timer.current.id);

    timer.current.paused = new Date();
  };

  const resumeTimer = () => {
    const { paused, started } = timer.current;

    const rest = TIMER_DELAY - (paused - started);

    setTimer(Math.max(minDelay, rest));
  };

  return { clearTimer, setTimer, pauseTimer, resumeTimer };
}