import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import { useTimer } from 'hooks/useTimer';

import { clear } from '../actions/flash';

function FlashItem({ id, level, message, clear }) {
  const { setTimer, pauseTimer, resumeTimer, clearTimer } = useTimer(() => clear(id));

  const removeItemFromFlashLIst = () => {
    clearTimer();

    clear(id);
  };

  useEffect(setTimer, []);

  return (
    <Alert color={level} key={id} onMouseEnter={pauseTimer} onMouseLeave={resumeTimer} toggle={removeItemFromFlashLIst}>
      {message}
    </Alert>
  );
}

FlashItem.propTypes = {
  id: PropTypes.number.isRequired,
  level: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  clear: PropTypes.func.isRequired,
};

const FlashList = ({ flash, clear }) => (
  <div className="Flash">
    {flash.map(item => <FlashItem key={item.id} {...item} clear={clear} />)}
  </div>
);


FlashList.propTypes = {
  flash: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      level: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
  clear: PropTypes.func.isRequired,
};

FlashList.defaultProps = {
  flash: [],
};

export default connect(
  state => ({ flash: state.flash }),
  dispatch => bindActionCreators({ clear }, dispatch),
)(FlashList);
