export const IMPORT_ACTIONS = {
  SETTINGS_LOADING: 'SETTINGS_LOADING',
  SETTINGS_LOADED: 'SETTINGS_LOADED',
  SET_SETTINGS_QUERY: 'SET_SETTINGS_QUERY',
  RESET_SETTINGS_QUERY: 'RESET_SETTINGS_QUERY',

  IMPORT_CREDENTIALS_LOADING: 'IMPORT_CREDENTIALS_LOADING',
  IMPORT_CREDENTIAL_LOADED: 'IMPORT_CREDENTIAL_LOADED',
  IMPORT_CREDENTIALS_LOADED: 'IMPORT_CREDENTIALS_LOADED',
  IMPORT_CREDENTIALS_FAILED: 'IMPORT_CREDENTIALS_FAILED',
};