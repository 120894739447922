import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { routerMiddleware } from 'connected-react-router';

import history from './history';

import createReducer from '../reducers';
import errorCatcher from './error-catcher';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (history) => {
  const middlewares = [
    routerMiddleware(history),
    errorCatcher,
    thunk,
    promise,
  ];

  const store = createStore(
    createReducer(history),
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  return store;
};

export default configureStore(history);