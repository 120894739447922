export default function flashReducer(state, action) {
  switch (action.type) {
    case 'FLASH':
      return [action.data, ...state];

    case 'CLEAR_FLASH':
      return state.filter(({ id }) => id !== action.id);

    default:
      return state || [];
  }
}
