const initialState = {
  companies: [],
  dealers: [],
  properties: [],
  units: [],
  offers: [],
  zipcodegroups: [],
  placeLists: [],
  places: [],
  importSources: [],
  persons: [],
};

export default function autocompleteReducer(state, action) {
  switch (action.type) {
    case 'AUTOCOMPLETE':
      return { ...state, [action.collection]: action.suggestions };

    default:
      return state || initialState;
  }
}
