import { putIn } from '../utils';

const initialState = {
  sent: [],
  preventSpam: false,
  byRecipient: {},
};

export default function emailReducer(state, action) {
  switch (action.type) {
    case 'EMAIL_SENT':
      return {
        ...state,
        sent: [...state.sent, action.id],
      };
    case 'EMAIL_PREVENT_SPAM':
      return {
        ...state,
        preventSpam: true,
      };
    case 'EMAIL_RESET_PREVENT_SPAM':
      return {
        ...state,
        preventSpam: false,
      };
    case 'EMAILS_BY_RECIPIENT_LOADED':
      return putIn(state, ['byRecipient', action.recipient], action.emails);
    default:
      return state || initialState;
  }
}
