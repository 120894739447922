export const EnumServiceType = {
  Category: 'category',
  Type: 'type',
  Subtype: 'subtype',
};

/** Temporary solution while we use the type field from offers */
export const ServiceTypesOfMedia = ['internet', 'cable', 'phone'];

export const ServiceTypesActions = {
  StartLoading: 'SERVICE_TYPES_LOADING',
  StopLoading: 'SERVICE_TYPES_LOADED',
};