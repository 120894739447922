function flash(level, message) {
  return (dispatch) => {
    const id = Math.random();

    if (message && typeof message === 'object') {
      if (message.body && message.body.type === 'ModelValidation' && message.body.data) {
        const { data } = message.body;

        const messages = [];

        if (Array.isArray(data)) {
          data.forEach(err => messages.push(err.message));
        } else {
          Object.keys(data).forEach(key => messages.push(data[key].message));
        }


        message = `Validation errors: ${messages.filter(Boolean).join(', ')}`;
      } else if (message.body && message.body.message) {
        message = message.body.message;
      } else if ('message' in message) {
        message = message.message;
      }
    }

    dispatch({ type: 'FLASH', data: { level, message, id } });
  };
}

export function success(message) {
  return flash('success', message);
}

export function error(message) {
  return flash('danger', message);
}

export function info(message) {
  return flash('info', message);
}

export function clear(id) {
  return { type: 'CLEAR_FLASH', id };
}
