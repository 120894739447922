
const config = window.ENV.FEATURE_TOGGLE || {};

export const FEATURE_TOGGLES = {
  PEOPLE_MODULE: 'PEOPLE_MODULE',
};

export const isFeatureEnabled = (feature) => {
  const featureKey = FEATURE_TOGGLES[feature];

  if (!featureKey) return false;
  if (featureKey in config) {
    return ['true', true].includes(config[featureKey]);
  }

  return false;
};