import { WebAuth } from 'auth0-js';
import jwt from 'jsonwebtoken';
import { USER_PERMISSIONS } from 'app.constants';
import { PUT, GET, POST } from './data';
import {
  success, error, info, clear,
} from './flash';

export const CLIENT_ID = window.ENV.AUTH0_CLIENT_ID;

const REQUESTED_SCOPE = [
  'openid',
  'superadmin',
  'read:offers',
  'create:offers',
  'update:offers',
  'delete:offers',
  'read:documents',
  'update:documents',
  'create:documents',
  'delete:documents',
  'read:companies',
  'create:companies',
  'update:companies',
  'delete:companies',
  'read:authorizations',
  'create:authorizations',
  'update:authorizations',
  'delete:authorizations',
  'read:emails',
  'delete:emails',
  'read:email-templates',
  'create:email-templates',
  'update:email-templates',
  'delete:email-templates',
  'read:activations',
  'create:activations',
  'update:activations',
  'delete:activations',
  'manage:promotions',
  'send:emails',
  'read:affiliates',
  'read:affiliate-fees',
  'read:affiliate-statements',
  'create:affiliate-statements',
  'read:manual-activations',
  'write:manual-activations',
  'profile',
  'email',
  'read:distribution-channels',
  'update:distribution-channels',
  'create:distribution-channels',
  'delete:distribution-channels',
  'create:payments',
  'list:payments',
  'read:properties',
  'create:properties',
  'update:properties',
  'delete:properties',
  'read:urlshorts',
  'update:urlshorts',
  'create:urlshorts',
  'delete:urlshorts',
  'read:crypt-delegations',
  'create:crypt-delegations',
  'delete:crypt-delegations',
  'read:assignments',
  'create:assignments',
  'debug',
  'manage:triggers',
  'manage:global-triggers',
  'manage:company-triggers',
  'manage:dealer-triggers',
  'read:dealer',
  'update:dealer',
  'read:custom-fields',
  'create:custom-fields',
  'update:custom-fields',
  'delete:custom-fields',
  'create:fulfillments',
  'read:fulfillments',
  'delete:import-credentials',
  'submit:private-data',
].join(' ');

// FIXME: make all of those configurable at build time:
const webAuth = new WebAuth({
  domain: window.ENV.AUTH0_DOMAIN,
  clientID: CLIENT_ID,
  responseType: 'token',
  audience: window.ENV.AUTH0_AUDIENCE,
  scope: REQUESTED_SCOPE,
});

export function setAuthInfo(info) {
  return { type: 'SET_AUTH_INFO', info };
}

export function setAuthError(error, source) {
  return { type: 'SET_AUTH_ERROR', error, source };
}

const MFA_REQUESTED_SCOPES = [USER_PERMISSIONS.DECRYPT_DATA].join(' ');

// For now permission is not used in the extendUserScope
// But if we will found a way to control token expiration we can use token directly for apis
// Therefore, we are now simply updating the current token with a new token with all available user scopes.
export function extendUserScope() {
  return new Promise((resolve) => {
    webAuth.popup.authorize({
      scope: `${REQUESTED_SCOPE} ${MFA_REQUESTED_SCOPES}`,
      owp: true,
    }, (err, res) => {
      if (err) {
        console.error(err.original, err.description);

        resolve(null);
      } else {
        resolve(setAuthInfo({
          token: res.accessToken,
          expiresAt: new Date(Date.now() + (parseInt(res.expiresIn, 10) * 1000)).toISOString(),
          scope: res.scope && res.scope.split(' '),
        }));
      }
    });
  });
}

const getRedirectURI = () => {
  let redirectUri = `${window.location.origin}${window.ENV.BASENAME}`;

  if (redirectUri[redirectUri.length - 1] !== '/') {
    redirectUri += '/';
  }

  return redirectUri;
};

export function authorize(isExpired) {
  webAuth.authorize({
    redirectUri: getRedirectURI(),
    customMessage: isExpired ? 'Session expired' : undefined,
  });

  return { type: 'AUTHORIZE' };
}

export async function renewSession() {
  const redirectUri = getRedirectURI();

  const now = Date.now();

  return new Promise((resolve, reject) => webAuth.checkSession({ redirectUri }, (err, res) => {
    if (err) {
      console.log(`[renewSession] Failed to renew session (redirectUri: ${JSON.stringify(redirectUri)}): ${JSON.stringify(err)}`);
      resolve(setAuthError(err, 'renewSession'));
    } else {
      console.log(`[renewSession] Renewed session successfully: ${JSON.stringify(res)}`);

      resolve(setAuthInfo({
        token: res.accessToken,
        expiresAt: new Date(now + (parseInt(res.expiresIn, 10) * 1000)).toISOString(),
        state: res.appState,
        scope: res.scope && res.scope.split(' '),
      }));
    }
  }));
}

export function parseHash(hash) {
  return (dispatch) => {
    dispatch({ type: 'PARSE_AUTH_HASH' });
    webAuth.parseHash({ hash }, async (err, response) => {
      if (err) {
        dispatch(setAuthError(err, 'parseHash'));
      } else {
        let profile;

        try {
          profile = await GET(response.accessToken, '/users/me');
        } catch (e) {
          console.log(`WARNING: failed to fetch user profile: ${e.message}`);
        }
        const decodedToken = jwt.decode(response.accessToken);
        // [TODO] is this going to change in the near future
        const limits = decodedToken['https://auth0.ideco.io/limits'];

        dispatch(setAuthInfo({
          token: response.accessToken,
          scope: (response.scope || REQUESTED_SCOPE).split(' '),
          expiresAt: new Date(new Date().getTime() + (response.expiresIn * 1000)).toISOString(),
          profile,
          limits,
        }));
      }
    });
  };
}

export async function checkPin(accessToken, { pin, activationId }) {
  const url = `/activation/activations/${activationId}/check-pin`;
  const response = await POST(accessToken, url, { pin });

  return response.json();
}

export function changePin(accessToken, oldPin, newPin) {
  return async (dispatch) => {
    const response = await POST(accessToken, '/users/me/change-pin', {
      old_pin: oldPin,
      new_pin: newPin,
    });

    if (response.ok) {
      dispatch(success('PIN successfully changed'));
      dispatch(refreshProfile(accessToken));
    } else {
      dispatch(error(`Failed to change PIN: ${response.error}`));
    }
  };
}

export function logout() {
  return { type: 'LOGOUT' };
}

export async function refreshProfile(accessToken) {
  const profile = await GET(accessToken, '/users/me');

  return setAuthInfo({ profile });
}

export function updateMe(accessToken, newProps) {
  return async (dispatch) => {
    dispatch(info('Updating User Data'));
    const response = await PUT(accessToken, '/users/me', newProps);

    if (response.status === 200) {
      const userInfo = await response.json();

      dispatch(setAuthInfo({ profile: userInfo }));
    }
    if (response.status >= 400) {
      dispatch(error(`Failed Updating User Data : ${response.status}`));
    } else {
      dispatch(clear('update-user'));
      dispatch(success('User Data Has Been Updated Successfuly'));
    }
  };
}

export function setLastActivity() {
  return { type: 'ACTIVITY', now: new Date().toISOString() };
}

export function getUserId() {
  const { token } = JSON.parse(window.localStorage.idecoAuthInfo);
  const { sub: id } = jwt.decode(token);

  return id;
}

export function warnAboutLogout() {
  return { type: 'WARN_ABOUT_LOGOUT' };
}
