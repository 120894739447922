import React, { Component } from 'react';
import PropTypes from 'prop-types';
import poweredBy from '../../../assets/src/svg/ideco-footer.svg';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <div className="footer">
        <span>Copyright &copy; {(new Date()).getFullYear()}
          &nbsp;<a href="https://ideco.io">Ideco.io LLC.</a> All rights reserved.
        </span>
        <img src={poweredBy} alt="Powered by Ideco" className="footerPowered" />
      </div>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
