import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import routes from './breadcrumbRoutes';

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <Breadcrumb>
      {breadcrumbs.map(({ key, match, breadcrumb }) => (
        <BreadcrumbItem key={key}>
          <NavLink to={match.url}>
            {breadcrumb}
          </NavLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default withBreadcrumbs(routes, { disableDefaults: true })(Breadcrumbs);
