import deepEqual from 'fast-deep-equal';
import { putIn } from '../utils';

const initialState = {
  byObject: {
    Person: {},
    Activation: {},
  },
  mineByType: {
    Activation: {
      assignments: [],
      activations: {},
      query: {},
      cursor: null,
      hasMore: true,
    },
    People: {
      assignments: [],
      people: {
        pages: {},
        records: {},
        pageInfo: {},
      },
      query: {},
      cursor: null,
      hasMore: true,
    },
  },
};

export default function assignmentsReducer(state, action) {
  switch (action.type) {
    case 'ASSIGNMENT_LOADED': {
      return putIn(state, ['byObject', action.objectType, action.objectId], action.assignment && !action.assignment.expired ? action.assignment : null);
    }

    case 'CLEAR_PROFILE_ACTIVATIONS':
      return putIn(state, ['mineByType', 'Activation'], initialState.mineByType.Activation);

    case 'PROFILE_ACTIVATIONS_LOADING':
      return putIn(state, ['mineByType', 'Activation', 'query'], action.query);

    case 'PROFILE_ACTIVATIONS_LOADED': {
      const { pageInfo, query } = action;

      if (!deepEqual(query, state.mineByType.Activation.query)) { // action belongs to an old request, ignore
        return state;
      }

      const filteredAssignments = action.assignments.filter(
        ({ object_id }) => action.activations.find(({ id }) => id === object_id));
      const assignments = [...state.mineByType.Activation.assignments, ...filteredAssignments];
      const activations = action.activations.reduce(
        (records, o) => ({ ...records, [o.id]: o }),
        state.mineByType.Activation.activations,
      );
      const newActivations = {
        cursor: action.pageInfo.page,
        assignments,
        activations,
        hasMore: pageInfo.page < pageInfo.total / pageInfo.perPage,
        query,
      };

      return putIn(state, ['mineByType', 'Activation'], newActivations);
    }

    case 'CLEAR_PROFILE_PEOPLE':
      return putIn(state, ['mineByType', 'People'], initialState.mineByType.People);

    case 'PROFILE_PEOPLE_LOADING':
      return putIn(state, ['mineByType', 'People', 'query'], action.query);

    case 'PROFILE_PEOPLE_LOADED': {
      const { pageInfo, query } = action;

      if (!deepEqual(query, state.mineByType.People.query)) { // action belongs to an old request, ignore
        return state;
      }

      const filteredAssignments = action.assignments
        .filter(({ object_id }) => action.people.find(({ id }) => id === object_id));
      const assignments = [...state.mineByType.People.assignments, ...filteredAssignments];
      const records = action.people.reduce(
        (acc, person) => ({ ...acc, [person.id]: person }),
        state.mineByType.People.people.records,
      );
      const pages = {
        ...state.mineByType.People.people.pages,
        [pageInfo.page]: action.people.map(({ id }) => id),
      };

      const newPeople = {
        cursor: action.pageInfo.page,
        assignments,
        people: {
          pages,
          records,
          pageInfo,
        },
        hasMore: pageInfo.page < pageInfo.total / pageInfo.perPage,
        query,
      };

      return putIn(state, ['mineByType', 'People'], newPeople);
    }

    default:
      return state || initialState;
  }
}
