import React, { Component, Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import idecoLogo from 'assets/src/svg/ideco.svg';
import * as authActions from './actions/auth';
import { DefaultLayout } from './components/containers';
import Loading from './components/common/Loading';
import styles from './AuthWrapper.module.scss';

function parseLimits(scope) {
  return (scope || []).reduce((limits, part) => {
    const match = part.match(/^limit:([^:]+):(.+)$/);

    if (match) {
      return { ...limits, [match[1]]: match[2] };
    }

    return limits;
  }, {});
}

class AuthWrapper extends Component {
  static childContextTypes = {
    accessToken: PropTypes.string,
    accessScope: PropTypes.arrayOf(PropTypes.string),
    accessLimits: PropTypes.object,
  }

  getChildContext() {
    return {
      accessToken: this.props.token,
      accessScope: this.props.profile ? this.props.profile.permissions : [],
      accessLimits: this.props.limits || parseLimits(this.props.scope),
    };
  }

  componentWillMount() {
    this.handleHashOrRedirect(this.props);
    this.props.setLastActivity();
    this.props.refreshProfile();
  }

  componentWillReceiveProps(props) {
    const now = new Date().getTime();
    const expiresAtTime = Date.parse(this.props.expiresAt);

    if (expiresAtTime < now) {
      this.handleHashOrRedirect(props, true);

      return;
    }
    this.handleHashOrRedirect(props);
    const lastActivityTime = Date.parse(this.props.lastActivity);

    if (new Date().getTime() - lastActivityTime > 5000) { // update lastActivity only every 5 sec.
      this.props.setLastActivity();
    }
  }

  render() {
    const {
      token, children, error, expiresAt, tokenPending, authorize, renewSession, showLogoutWarning,
    } = this.props;

    if (error) {
      return (
        <Container className="d-flex align-items-center justify-content-center" style={{ height: '100vh', flexDirection: 'column' }}>
          <img className={styles.idecoLogo} src={idecoLogo} alt="Ideco" />
          <div className="text-center">
            <h1>You have been logged out</h1>
            <p>Your session has expired. Click "Log in" to be directed to the login page.</p>
            <Button color="primary" onClick={authorize}>Log in</Button>
          </div>
        </Container>
      );
    }

    if (tokenPending) {
      return <Loading />;
    }

    if (token) {
      if (expiresAt) {
        const now = new Date().getTime();
        const expiresAtTime = Date.parse(expiresAt);

        if (expiresAtTime < now) {
          authorize(true);

          return <div />;
        }
      }

      return (
        <DefaultLayout>
          {children}
          {showLogoutWarning && <LogoutWarningModal expiresAt={expiresAt} onRenew={renewSession} />}
        </DefaultLayout>
      );
    }

    return <Loading />;
  }

  // eslint-disable-next-line class-methods-use-this
  handleHashOrRedirect(props, hasExpired) {
    const { token, tokenPending, authorize, parseHash } = props;

    if (window.location.hash.match(/access_token|error_description/)) {
      parseHash(window.location.hash);
      window.location.hash = '';

      return;
    }

    if (!token && !tokenPending) {
      sessionStorage.locationBeforeAuth = document.location.pathname;
      authorize(hasExpired);

      return;
    }

    if (sessionStorage.locationBeforeAuth) {
      const { locationBeforeAuth } = sessionStorage;

      delete sessionStorage.locationBeforeAuth;
      document.location = locationBeforeAuth;
    }
  }
}

function CountdownSeconds({ date }) {
  const computeState = useCallback(() => Math.round((Date.parse(date) - new Date().getTime()) / 1000), [date]);

  const [seconds, setSeconds] = useState(computeState);

  useEffect(() => {
    const intervalId = setInterval(() => setSeconds(computeState), 750);

    return () => clearInterval(intervalId);
  }, [computeState]);

  return <Fragment>{seconds >= 0 ? `in ${seconds} seconds` : 'now'}</Fragment>;
}

function LogoutWarningModal({ expiresAt, onRenew }) {
  return (
    <Modal isOpen>
      <ModalHeader>Automatic logout</ModalHeader>
      <ModalBody>
        Due to inactivity you will be logged out <CountdownSeconds date={expiresAt} />.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onRenew}>Stay logged in</Button>
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(connect(
  state => state.auth,
  dispatch => bindActionCreators({ ...authActions, push }, dispatch),
)(AuthWrapper));
