import { putIn } from '../utils';

const initialState = {
  records: {},
  createResult: null,
  patchResult: null,
  rolesByUserId: {},
  permissionsByUserId: {},
  rolesAndPermissions: null,
  availablePermissions: null,
};

export default function usersReducer(state, action) {
  switch (action.type) {
    case 'LOAD_USER':
      return { ...state, records: { ...state.records, [action.id]: action.user } };

    case 'CREATE_USER_BEGIN':
      return { ...state, createResult: null };

    case 'CREATE_USER_SUCCESS':
      return { ...state, createResult: { success: true, response: action.response } };

    case 'CREATE_USER_ERROR':
      return { ...state, createResult: { success: false, error: action.error } };

    case 'PATCH_USER_BEGIN':
      return { ...state, patchResult: null };

    case 'PATCH_USER_SUCCESS':
      return {
        ...state,
        patchResult: {
          success: true,
          response: action.response,
        },
        records: { ...state.records, [action.response.user_id]: action.response },
      };

    case 'PATCH_USER_ERROR':
      return { ...state, patchResult: { success: false, error: action.error } };

    case 'LOAD_USER_ROLES':
      return putIn(state, ['rolesByUserId', action.userId], action.roles);

    case 'LOAD_ROLES_AND_PERMISSIONS':
      return { ...state, rolesAndPermissions: action.rolesAndPermissions };

    case 'LOAD_USER_PERMISSIONS':
      return putIn(state, ['permissionsByUserId', action.userId], action.permissions);

    case 'LOAD_AVAILABLE_PERMISSIONS':
      return { ...state, availablePermissions: action.availablePermissions };

    default:
      return state || initialState;
  }
}
