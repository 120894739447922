const initialState = {
  views: {},
};

export default function(state, action) {
  switch (action.type) {
  case 'DOCUMENT_DATA_LOADED':
    return { ...state, views: { ...state.views, [action.id]: {...state.views[action.id], text: action.text,objectUrl: action.objectUrl}}};
  case 'IMAGE_DATA_LOADED':
    return { ...state, views: { ...state.views, [action.id]: { ...state.views[action.id], thumbnail: action.objectUrl}}};

  case 'DOCUMENT_DATA_FREE':
    {
      const { id } = action;
      URL.revokeObjectURL(state.views[id].dataUrl);
      return { ...state, views: { ...state.views, [id]: { ...state.views[id], objectUrl: undefined, text: undefined} }};
    }
  case 'IMAGE_DATA_FREE':
    {
      const { id } = action;
      URL.revokeObjectURL(state.views[id].thumbnail);
      return { ...state, views: { ...state.views, [id]: { ...state.views[id], thumbnail: undefined} }};
    }

  default:
    return state || initialState;
  }
}
