import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import store from 'resources/store';
import history from 'resources/history';

import { handleExpiringToken } from 'resources/auth/auth.handlers';

import App from './App';
import AuthWrapper from './AuthWrapper';

import './index.css';

const checkPageVisibility = () => {
  if (document.visibilityState === 'visible') {
    handleExpiringToken();
  }
};

document.addEventListener('visibilitychange', checkPageVisibility);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AuthWrapper>
        <App />
      </AuthWrapper>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
