import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Perm extends Component {
  static propTypes = {
    perm: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    noLimit: PropTypes.string,
    fallback: PropTypes.node,
  };

  static contextTypes = {
    accessScope: PropTypes.arrayOf(PropTypes.string),
    accessLimits: PropTypes.object,
  };

  render() {
    const { accessScope, accessLimits } = this.context;
    const { perm, children, noLimit, fallback } = this.props;

    return accessScope.includes(perm) && !accessLimits[noLimit] ? children : fallback || null;
  }
}
