import { setPage, addRecords } from './data';

const initialState = {
  properties: {
    pages: {},
    records: {},
  },
  units: {
    pages: {},
    records: {},
  },
  records: {},
};

export default function propertiesReducer(state, action) {
  switch (action.type) {
    case 'PROPERTY_LOADED':
      return addRecords(state, 'properties', { [action.id]: action.property });

    case 'PROPERTIES_LOADING':
      return setPage(state, 'properties', action.query.page, null);

    case 'PROPERTIES_LOADED': {
      const ids = action.properties.map(property => property.id);
      const records = action.properties.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'properties', action.query.page, ids, action.pageInfo);

      return addRecords(stateWithPage, 'properties', records);
    }

    case 'UNIT_LOADED':
      return addRecords(state, 'units', { [action.id]: action.property });

    case 'UNITS_LOADING':
      return setPage(state, 'units', action.query.page, null);

    case 'UNITS_LOADED': {
      const ids = action.properties.map(property => property.id);
      const records = action.properties.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'units', action.query.page, ids, action.pageInfo);

      return addRecords(stateWithPage, 'units', records);
    }

    default:
      return state || initialState;
  }
}
