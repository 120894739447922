import React, { Component } from 'react';
import {
  UncontrolledDropdown, DropdownItem, DropdownMenu, Col, DropdownToggle, Row, Nav, Badge,
} from 'reactstrap';
import PropTypes from 'prop-types';

import {
  AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler,
} from '@coreui/react';
import sygnet from '../../../assets/src/svg/sygnet.svg';
import Perm from '../../Perm';

const propTypes = {
  children: PropTypes.node,
  profile: PropTypes.shape({
    picture: PropTypes.string,
    email: PropTypes.string,
  }),
};

const defaultProps = {
  profile: {
    picture: '',
    email: '',
  },
};

class DefaultHeader extends Component {
  render() {
    // eslint-disable-next-line
    const { children, expiresIn, profile, logout, logo, logoHeight, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: logo, height: logoHeight, alt: 'My providers',
          }}
          minimized={{
            src: sygnet, width: 30, height: 30, alt: 'My providers',
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Perm perm="debug">
          <Badge>
            <div className="d-block d-sm-none">XS</div>
            <div className="d-none d-sm-block d-md-none">SM</div>
            <div className="d-none d-md-block d-lg-none">MD</div>
            <div className="d-none d-lg-block d-xl-none">LG</div>
            <div className="d-none d-xl-block">XL</div>
          </Badge>
        </Perm>
        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <UncontrolledDropdown>
              <DropdownToggle nav className="mr-3">
                <Col>
                  <Row className="d-flex align-items-center">
                    <img src={profile.picture} style={{ height: '30px' }} className="img-avatar" alt={profile.email} />
                    <span className="d-md-down-none">&nbsp;&nbsp;{profile.email}</span>
                  </Row>
                </Col>
              </DropdownToggle>
              <DropdownMenu className="mt-1">
                <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
                <DropdownItem onClick={() => { this.props.history.push('/profile'); }}>
                  <i className="far fa-user" />
                  {' '}
  Profile
                </DropdownItem>
                <DropdownItem onClick={logout}>
                  <i className="far fa-lock" />
                  {' '}
  Logout
                </DropdownItem>
                <DropdownItem className="text-center">
  Token expires in:
                  {expiresIn}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </AppHeaderDropdown>
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/* <AppAsideToggler className="d-lg-none" mobile /> */}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
